import { ProdRegions } from "./common-regions";

export const CI_BASE = 'sales.ai.dynamics.com';

export enum SciTestRegions {
  local = 'local',
  e2e = 'e2e',
}

export enum SciDevRegions {
  dev = 'dev',
  dev2 = 'dev2',
  dev2pe = 'pe-dev2',
  dev3 = 'dev3',
  dev3pe = 'pe-dev3',
  dev4 = 'dev4',
  dev4pe = 'pe-dev4',
  dev7 = 'dev7',
  dev7pe = 'pe-dev7',
  dev8 = 'dev8',
  dev8pe = 'pe-dev8',
  dev9 = 'dev9',
  dev9pe = 'pe-dev9',
  dev11 = 'dev11',
  dev11pe = 'pe-dev11',
}

export const SciRegion = { ...SciTestRegions, ...SciDevRegions, ...ProdRegions };
export type SciRegion = SciTestRegions | SciDevRegions | ProdRegions;
