import { LocalStorageKeys, getLocalStorageValue } from '../../local-storage';
import { CI_BASE, SalesCopilotAppId, SciRegion } from '../regions-constants';
import { isSPDevOrTipEnv, isSPFreEnv } from '../../teams-env-utils';
import { getRegionByDynamicsOrgUrl } from './crm-mappings';
import { CI_DEV_APP_ID } from '../../application/service-application.constants';

export const SciApiServerUrls = {
  ...Object.values(SciRegion).reduce((map, region) => {
    map[region] = `https://api-${region}${region.valueOf().includes('dev') ? '.dev' : ``}.${CI_BASE}`;
    return map;
  }, {} as Record<SciRegion, string>),
  [SciRegion.local]: `http://localhost:8080`,
  [SciRegion.e2e]: `http://localhost:3000`,
  [SciRegion.prod]: `https://api-${SciRegion.weur}.${CI_BASE}`,
};

export const SciUIServerUrls = {
  ...Object.values(SciRegion).reduce((map, region) => {
    map[region] = `https://ui-${region}${region.valueOf().includes('dev') ? '.dev' : ``}.${CI_BASE}`;
    return map;
  }, {} as Record<SciRegion, string>),
  [SciRegion.local]: `http://localhost:3000`,
  [SciRegion.e2e]: `http://localhost:3000`,
  [SciRegion.dev]: `https://dev.${CI_BASE}`,
  [SciRegion.tip1]: `https://tip.${CI_BASE}`,
  [SciRegion.prod]: `https://${CI_BASE}`,
};

export const SciAuthAudiences = {
  ...Object.values(SciRegion).reduce((map, region) => {
    map[region] = `https://ui-${region}${region.valueOf().includes('dev') ? '.dev' : ``}.${CI_BASE}`;
    return map;
  }, {} as Record<SciRegion, string>),
  [SciRegion.local]: `http://localhost:3000`,
  [SciRegion.e2e]: `http://localhost:3000`,
  [SciRegion.dev]: `api://${CI_DEV_APP_ID}`,
  [SciRegion.tip1]: `https://tip.${CI_BASE}`,
  [SciRegion.prod]: `https://${CI_BASE}`,
};

export const getSciApiServerUrl = (region: SciRegion) => SciApiServerUrls[(getLocalStorageValue(LocalStorageKeys.ApiServerRegion) as SciRegion) || region];
export const getSciUIServerUrl = (region: SciRegion) => SciUIServerUrls[region];
export const getSciAuthAudience = (region: SciRegion) => SciAuthAudiences[region];

export function getCurrentCIAppRegion(url: string, installedSalesCopilotAppId?: SalesCopilotAppId): SciRegion {
  if (installedSalesCopilotAppId && isSPDevOrTipEnv(installedSalesCopilotAppId)) {
    return SciRegion.dev2;
  }
  if (installedSalesCopilotAppId && isSPFreEnv(installedSalesCopilotAppId)) {
    return SciRegion.tip1;
  }

  const searchParams = new URLSearchParams(url);
  const dataverseOrgUrlByQueryParam = searchParams.get('dvOrgUrl') || undefined;
  const region = dataverseOrgUrlByQueryParam
    ? getRegionByDynamicsOrgUrl(dataverseOrgUrlByQueryParam)
    : SciRegion.nam;
  return region;
}