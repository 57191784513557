// The features within this object can be enabled from ECS. Features that can be enabled from CRM admin settings are listed in settingsFeatures within settings-features-utils.js.
export const FEATURES = {
  TRY_AND_BUY: 'TryAndBuy',
  CALL_LANGUAGES: 'CallLanguages',
  ADMIN_SELLERS: 'AdminSellers',
  REALTIME_ACTION_ITEMS: 'RealtimeActionItems',
  RECENTLY_ACCESSED_ORGANIZATION_LIST: 'RecentlyAccessedOrganizationList',
  CRM_DEV_BANNER: 'CrmDevBanner',
  NEW_THIRD_PARTY_ADMIN_SETTINGS: 'NewThirdPartyAdminSettings',
  SHOW_ON_PREM_CALL_PROVIDERS_BY_DEFAULT: 'ShowOnPremCallProvidersByDefault',
  SEND_ACS_LOGS_ALWAYS: 'sendAcsLogsAlways',
  USE_OLDER_ACS_VERSION: 'useOlderACSVersion',
  IGNORE_SEARCH_CONTACTS: 'ignoreSearchContacts',
  IGNORE_SEARCH_ACCOUNTS: 'ignoreSearchAccounts',
  IGNORE_SEARCH_LEADS: 'ignoreSearchLeads',
  IGNORE_SEARCH_TEAMS_USERS: 'ignoreSearchTeamsUsers',
  HIDE_SAFE_TRANSFER: 'hideSafeTransfer',
  REDACT_PII_FROM_TRANSCRIPT: 'redactPIIFromTranscript',
  ENABLE_SYSTEM_MONITORING: 'enableSystemMonitoring',
  DISABLE_MANUALLY_PHONE_NUMBER_CALLING: 'disableManuallyPhoneNumberCalling',
  USE_DATAVERSE_STORAGE: 'useDataverseStorage',
  DIALER_FORM_CUSTOMIZATIONS_ENABLEMENT_BY_ADMIN: 'dialerFormCustomizationsEnablementByAdmin',
  DIALER_HEADPHONE_CONTROL: 'dialerHeadphoneControl',
  SEARCH_3_LAST_DIGITS_CRM_PHONE_NUMBER: 'search3LastDigitsCrmPhoneNumber',
  HIDE_LICENSE_USAGE_FROM_SAA_SETTINGS: 'HideLicenseUsageFromSAASettings',
  HIDE_INCOMING_CALL_MODAL_ON_DO_NOT_DISTURB: 'HideIncomingCallModalOnDoNotDisturb',
  SEARCH_ACTIVE_CRM_RECORDS_FIRST: 'searchActiveCrmRecordsFirst',
  USE_STORAGE_NAME_ALWAYS: 'UseStorageNameAlways',
  COMPLIANCE_BOUNDARY: 'complianceBoundary',
  CREATE_NOTE_ON_DEMAND: 'createNoteOnDemand',
  CLICK_TO_DIAL_USE_NUMBER_FROM_EVENT: 'clickToDialUseNumberFromEvent',
};
