import { SciRegion } from '@sci/common';

export const CRM_TIP_SUFFIX_URL = 'crm10.dynamics.com';
export const CI_BASE = 'sales.ai.dynamics.com';
export const MR_BASE = 'api.media.recording.dynamics.com';

export const CI_CRM_ENV_LOCAL_STORAGE_KEY = 'CI_CRM_ENV';
export const CI_CRM_ENV_DIALER_LOCAL_STORAGE_KEY = 'CI_CRM_ENV_DIALER_IFRAME';
export const CI_CRM_ENV_IFRAMES_LOCAL_STORAGE_KEY = 'CI_CRM_ENV_IFRAMES'; // This key not include the Dialer iFrame, It has its own key (CI_CRM_ENV_DIALER_LOCAL_STORAGE_KEY)
export const CI_CRM_ENV_BE_LOCAL_STORAGE_KEY = 'CI_CRM_ENV_BE';
export const MR_ENV_LOCAL_STORAGE_KEY = 'mediarecordingenv';

export const CRM_ENVS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV', // backward compatible
  DEV2: 'DEV2',
  DEV2PE: 'DEV2PE',
  DEV3: 'DEV3',
  DEV3PE: 'DEV3PE',
  DEV4: 'DEV4',
  DEV4PE: 'DEV4PE',
  DEV7: 'DEV7',
  DEV7PE: 'DEV7PE',
  DEV8: 'DEV8',
  DEV8PE: 'DEV8PE',
  DEV9: 'DEV9',
  DEV9PE: 'DEV9PE',
  DEV11: 'DEV11',
  DEV11PE: 'DEV11PE',
  CSC_DEV: 'CSC_DEV',
  [SciRegion.tip1]: SciRegion.tip1,
  TIP: 'TIP', // backward compatible
  [SciRegion.nam]: SciRegion.nam,
  [SciRegion.nam2]: SciRegion.nam2,
  [SciRegion.ssam]: SciRegion.ssam,
  [SciRegion.ccan]: SciRegion.ccan,
  [SciRegion.weur]: SciRegion.weur,
  [SciRegion.seapj]: SciRegion.seapj,
  [SciRegion.eoce]: SciRegion.eoce,
  [SciRegion.ejpn]: SciRegion.ejpn,
  [SciRegion.cind]: SciRegion.cind,
  [SciRegion.cfra]: SciRegion.cfra,
  [SciRegion.nzaf]: SciRegion.nzaf,
  [SciRegion.nuae]: SciRegion.nuae,
  [SciRegion.swiss]: SciRegion.swiss,
  [SciRegion.ger]: SciRegion.ger,
  [SciRegion.norway]: SciRegion.norway,
  [SciRegion.korea]: SciRegion.korea,
  PROD: 'PROD'
};

export const CI_CLIENT_IDS = {
  TIP_AND_PROD: '373aedd7-18be-428f-81e6-8174c02cf6d8',
  DEV: '94569afe-f117-41fe-a995-fb5862a730ec'
};

export const SERVER_API_URLS = {
  LOCAL: `https://devtest.dev.${CI_BASE}`,
  DEV: `https://dev2.dev.${CI_BASE}`, // backward compatible
  DEV2: `https://dev2.dev.${CI_BASE}`,
  DEV2PE: `https://pe-dev2.dev.${CI_BASE}`,
  DEV3: `https://dev3.dev.${CI_BASE}`,
  DEV3PE: `https://pe-dev3.dev.${CI_BASE}`,
  DEV4: `https://dev4.dev.${CI_BASE}`,
  DEV4PE: `https://pe-dev4.dev.${CI_BASE}`,
  DEV7: `https://dev7.dev.${CI_BASE}`,
  DEV7PE: `https://pe-dev7.dev.${CI_BASE}`,
  DEV8: `https://dev8.dev.${CI_BASE}`,
  DEV8PE: `https://pe-dev8.dev.${CI_BASE}`,
  DEV9: `https://dev9.dev.${CI_BASE}`,
  DEV9PE: `https://pe-dev9.dev.${CI_BASE}`,
  DEV11: `https://dev11.dev.${CI_BASE}`,
  DEV11PE: `https://pe-dev11.dev.${CI_BASE}`,
  CSC_DEV: `https://csc-dev-usa.${CI_BASE}`, // show be fixed to csc-dev-usa.${CI_BASE} waiting for csc PR
  [SciRegion.tip1]: `https://${SciRegion.tip1}.${CI_BASE}`,
  TIP: `https://tip1.${CI_BASE}`, // backward compatible
  [SciRegion.nam]: `https://${SciRegion.nam}.${CI_BASE}`,
  [SciRegion.nam2]: `https://${SciRegion.nam2}.${CI_BASE}`,
  [SciRegion.ssam]: `https://${SciRegion.ssam}.${CI_BASE}`,
  [SciRegion.ccan]: `https://${SciRegion.ccan}.${CI_BASE}`,
  [SciRegion.weur]: `https://${SciRegion.weur}.${CI_BASE}`,
  [SciRegion.seapj]: `https://${SciRegion.seapj}.${CI_BASE}`,
  [SciRegion.eoce]: `https://${SciRegion.eoce}.${CI_BASE}`,
  [SciRegion.ejpn]: `https://${SciRegion.ejpn}.${CI_BASE}`,
  [SciRegion.cind]: `https://${SciRegion.cind}.${CI_BASE}`,
  [SciRegion.gbr]: `https://${SciRegion.gbr}.${CI_BASE}`,
  [SciRegion.cfra]: `https://${SciRegion.cfra}.${CI_BASE}`,
  [SciRegion.nzaf]: `https://${SciRegion.nzaf}.${CI_BASE}`,
  [SciRegion.nuae]: `https://${SciRegion.nuae}.${CI_BASE}`,
  [SciRegion.swiss]: `https://${SciRegion.swiss}.${CI_BASE}`,
  [SciRegion.ger]: `https://${SciRegion.ger}.${CI_BASE}`,
  [SciRegion.norway]: `https://${SciRegion.norway}.${CI_BASE}`,
  [SciRegion.korea]: `https://${SciRegion.korea}.${CI_BASE}`,
  PROD: `https://nam.${CI_BASE}`
};

export const CI_CRM_URLS = {
  LOCAL: 'http://localhost:3005',
  DEV: `https://dev.${CI_BASE}`, // backward compatible
  DEV2: `https://ui-dev2.dev.${CI_BASE}`,
  DEV2PE: `https://ui-pe-dev2.dev.${CI_BASE}`,
  DEV3: `https://ui-dev3.dev.${CI_BASE}`,
  DEV3PE: `https://ui-pe-dev3.dev.${CI_BASE}`,
  DEV4: `https://ui-dev4.dev.${CI_BASE}`,
  DEV4PE: `https://ui-pe-dev4.dev.${CI_BASE}`,
  DEV7: `https://ui-dev7.dev.${CI_BASE}`,
  DEV7PE: `https://ui-pe-dev7.dev.${CI_BASE}`,
  DEV8: `https://ui-dev8.dev.${CI_BASE}`,
  DEV8PE: `https://ui-pe-dev8.dev.${CI_BASE}`,
  DEV9: `https://ui-dev9.dev.${CI_BASE}`,
  DEV9PE: `https://ui-pe-dev9.dev.${CI_BASE}`,
  DEV11: `https://ui-dev11.dev.${CI_BASE}`,
  DEV11PE: `https://ui-pe-dev11.dev.${CI_BASE}`,
  CSC_DEV: `https://ui-csc-dev-usa.${CI_BASE}`,
  [SciRegion.tip1]: `https://ui-${SciRegion.tip1}.${CI_BASE}`,
  TIP: `https://tip.${CI_BASE}`, // backward compatible
  [SciRegion.nam]: `https://ui-${SciRegion.nam}.${CI_BASE}`,
  [SciRegion.nam2]: `https://ui-${SciRegion.nam2}.${CI_BASE}`,
  [SciRegion.ssam]: `https://ui-${SciRegion.ssam}.${CI_BASE}`,
  [SciRegion.ccan]: `https://ui-${SciRegion.ccan}.${CI_BASE}`,
  [SciRegion.weur]: `https://ui-${SciRegion.weur}.${CI_BASE}`,
  [SciRegion.seapj]: `https://ui-${SciRegion.seapj}.${CI_BASE}`,
  [SciRegion.eoce]: `https://ui-${SciRegion.eoce}.${CI_BASE}`,
  [SciRegion.ejpn]: `https://ui-${SciRegion.ejpn}.${CI_BASE}`,
  [SciRegion.cind]: `https://ui-${SciRegion.cind}.${CI_BASE}`,
  [SciRegion.gbr]: `https://ui-${SciRegion.gbr}.${CI_BASE}`,
  [SciRegion.cfra]: `https://ui-${SciRegion.cfra}.${CI_BASE}`,
  [SciRegion.nzaf]: `https://ui-${SciRegion.nzaf}.${CI_BASE}`,
  [SciRegion.nuae]: `https://ui-${SciRegion.nuae}.${CI_BASE}`,
  [SciRegion.swiss]: `https://ui-${SciRegion.swiss}.${CI_BASE}`,
  [SciRegion.ger]: `https://ui-${SciRegion.ger}.${CI_BASE}`,
  [SciRegion.norway]: `https://ui-${SciRegion.norway}.${CI_BASE}`,
  [SciRegion.korea]: `https://ui-${SciRegion.korea}.${CI_BASE}`,
  PROD: `https://${CI_BASE}`
};

export const CI_IFRAME_CRM_URLS = {
  ...CI_CRM_URLS,
  LOCAL: 'https://devtest.dev.sales.ai.dynamics.com',
};

const CI_HOSTING_DEV_URL = 'https://cihosting.blob.core.windows.net/dev';
const CI_HOSTING_TIP_URL = 'https://cihosting.blob.core.windows.net/tip';
const CI_HOSTING_PROD_URL = 'https://cihosting.blob.core.windows.net/prod';

export const CI_HOSTING_URLS = {
  LOCAL: CI_HOSTING_DEV_URL,
  DEV: CI_HOSTING_DEV_URL, // backward compatible
  DEV2: CI_HOSTING_DEV_URL,
  DEV3: CI_HOSTING_DEV_URL,
  DEV4: CI_HOSTING_DEV_URL,
  DEV7: CI_HOSTING_DEV_URL,
  DEV8: CI_HOSTING_DEV_URL,
  DEV9: CI_HOSTING_DEV_URL,
  DEV11: CI_HOSTING_DEV_URL,
  CSC_DEV: CI_HOSTING_TIP_URL,
  [SciRegion.tip1]: CI_HOSTING_TIP_URL,
  TIP: CI_HOSTING_TIP_URL, // backward compatible
  [SciRegion.nam]: CI_HOSTING_PROD_URL,
  [SciRegion.nam2]: CI_HOSTING_PROD_URL,
  [SciRegion.ssam]: CI_HOSTING_PROD_URL,
  [SciRegion.ccan]: CI_HOSTING_PROD_URL,
  [SciRegion.weur]: CI_HOSTING_PROD_URL,
  [SciRegion.seapj]: CI_HOSTING_PROD_URL,
  [SciRegion.eoce]: CI_HOSTING_PROD_URL,
  [SciRegion.ejpn]: CI_HOSTING_PROD_URL,
  [SciRegion.cind]: CI_HOSTING_PROD_URL,
  [SciRegion.gbr]: CI_HOSTING_PROD_URL,
  [SciRegion.cfra]: CI_HOSTING_PROD_URL,
  [SciRegion.nzaf]: CI_HOSTING_PROD_URL,
  [SciRegion.nuae]: CI_HOSTING_PROD_URL,
  [SciRegion.swiss]: CI_HOSTING_PROD_URL,
  [SciRegion.ger]: CI_HOSTING_PROD_URL,
  [SciRegion.norway]: CI_HOSTING_PROD_URL,
  [SciRegion.korea]: CI_HOSTING_PROD_URL,
  PROD: CI_HOSTING_PROD_URL
};

export const MEDIA_RECORDING_SERVER_URLS = {
  getBase: (base) => `https://${base}.${MR_BASE}`,
  TIP: `https://tip.${MR_BASE}`,
  PROD: `https://${MR_BASE}`
};

export const MR_ENVS = {
  TIP: 'https://tip.api.media.recording.dynamics.com',
  DEV2: 'https://2.dev.api.media.recording.dynamics.com',
  DEV2PE: 'https://pe2.dev.api.media.recording.dynamics.com',
  DEV3: 'https://3.dev.api.media.recording.dynamics.com',
  DEV3PE: 'https://pe3.dev.api.media.recording.dynamics.com',
  DEV4: 'https://4.dev.api.media.recording.dynamics.com',
  DEV4PE: 'https://pe4.dev.api.media.recording.dynamics.com',
  DEV7: 'https://7.dev.api.media.recording.dynamics.com',
  DEV7PE: 'https://pe7.dev.api.media.recording.dynamics.com',
  DEV9: 'https://9.dev.api.media.recording.dynamics.com',
  DEV9PE: 'https://pe9.dev.api.media.recording.dynamics.com',
  DEV10: 'https://10.dev.api.media.recording.dynamics.com',
  DEV11: 'https://11.dev.api.media.recording.dynamics.com',
  DEV11PE: 'https://pe11.dev.api.media.recording.dynamics.com',
};

export const MR_DEV_CLIENT_IDS = {
  DEV2: '399ccdfa-40af-4d33-b122-d0146fcc5c90',
  DEV7: 'd5b15853-62ee-442f-8797-76db6cc384e8',
  DEV9: '48892d8c-41fd-4e07-9212-536fa9c99644',
  DEV11: '48892d8c-41fd-4e07-9212-536fa9c99644'
};