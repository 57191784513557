/* eslint-disable no-unused-vars */
import { toInteger } from 'lodash';
import { transformCall, transformComment, transformComments, transformEntityDetails } from '../filters/call-filters/call-filters';
import * as callRestService from '../rest-services/call-rest-service';

export const getCall = async (id, params, forceFetch, shouldAvoidTransformCall) => {
  let conversationId = id;
  const isOldId = Boolean(toInteger(id));
  if (isOldId) {
    const conversationData = await callRestService.loadConversationId(id, {}, forceFetch);
    conversationId = conversationData.data;
  }
  const { data: callData } = await callRestService.loadCall(conversationId, params, forceFetch);

  return shouldAvoidTransformCall ? callData : transformCall({ callData });
};

export const getOrg = async (params) => {
  const { data } = await callRestService.loadOrg(params.bodyParams, params.isGlobalSettings);
  return data;
};

export const getComments = async (callId, forceFetch, useCommentsApiV2) => {
  const comments = useCommentsApiV2 ?
      await callRestService.loadCommentsV2(callId, forceFetch) :
      await callRestService.loadComments(callId, forceFetch);
  return transformComments(comments.data);
};

export const addComment = async (callId, fragmentIdentifier, commentText, durationInMs, offsetInMs, useCommentsApiV2) => {
  const comment = useCommentsApiV2 ?
      await callRestService.addCommentV2(callId, fragmentIdentifier, commentText, durationInMs, offsetInMs) :
      await callRestService.addComment(callId, fragmentIdentifier, commentText);
  return transformComment(comment.data);
};

export const updateComment = async (callId, fragmentIdentifier, commentId, commentText, useCommentsApiV2) => {
  const comment = useCommentsApiV2 ?
      await callRestService.updateCommentV2(callId, commentId, commentText) :
      await callRestService.updateComment(callId, fragmentIdentifier, commentId, commentText);
  return transformComment(comment.data);
};

export const deleteComment = async (callId, fragmentIdentifier, commentId, useCommentsApiV2) =>
    useCommentsApiV2 ?
        callRestService.deleteCommentV2(callId, commentId) :
        callRestService.deleteComment(callId, fragmentIdentifier, commentId);

// Used by pages that are not in use, the outdated API was removed
export const getOpportunityDetails = async (opportunityId) => { };

// Used by pages that are not in use, the outdated API was removed
export const getSuggestedOpportunity = async () => { };

// Used by pages that are not in use, the outdated API was removed
export const getOpportunityOptions = async (text) => { };

// Used by pages that are not in use, the outdated API was removed
export const addOpportunity = async (callId, opportunityId) => { };

// Used by pages that are not in use, the outdated API was removed
export const removeOpportunity = async (callId) => { };

export const getAllColaEntitiesOptions = async (text, types) => {
  const { data } = await callRestService.getAllColaEntitiesOptions(text, types);
  return transformEntityDetails(data);
};

export const relateEntityToCall = async (conversationId, entityType, entityId, entityName) => {
  await callRestService.addEntity(conversationId, entityType, entityId, entityName);
};

export const updateParticipant = async (conversationId, participantId, detailsToUpdate) => {
  const { data: updatedParticipant } = await callRestService.updateParticipant(conversationId, participantId, detailsToUpdate);
  return updatedParticipant;
};

export const removeEntityFromCall = async (conversationId, entityId) => {
  await callRestService.removeEntity(conversationId, entityId);
};

export const createNewEntityAndRelateToCall = async (conversationId, typeToCreate, entityName) => {
  const { data } = await callRestService.addEntity(conversationId, typeToCreate, null, entityName);
  return data;
};

export const addTag = async (callId, tag) => callRestService.addTag(callId, tag);

export const removeTag = async (callId, tag) => callRestService.removeTag(callId, tag);

export const getUserSummary = async (conversationId) => {
  const { data } = await callRestService.getUserSummary(conversationId, true);
  return data;
};

export const editUserSummary = async (conversationId, text) => callRestService.editUserSummary(conversationId, text);
